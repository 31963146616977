<template>
  <div
    class="flex flex-col flex-0"
    style="max-height: -webkit-fill-available;"
  >
    <div class="flex flex-1 overflow-auto" />
    <div
      class="flex flex-col min-h-0 bg-gray-200 border-t dark:border-gray-700 dark:bg-neutral-800"
    >
      <div class="w-full px-3 py-3 mx-auto sm:px-6 lg:px-8">
        <div class="flex flex-wrap items-center justify-between">
          <div class="flex items-center flex-1">
            <button
              type="button"
              class="flex p-2 text-gray-700 rounded-lg cursor-pointer dark:text-gray-200 hover:text-purple-800"
              aria-controls="headlessui-disclosure-panel-8"
              @click.prevent="togglePanel"
            >
              <fa-icon
                v-if="open"
                icon="chevron-down"
              />
              <fa-icon
                v-else
                icon="chevron-up"
              />
            </button>
            <template v-if="hasActiveUploads">
              ({{ activeUploads.length }})
              <!-- <fa-icon icon="warning" class="mr-2" v-if="showError"/> -->
              <!-- <fa-icon icon="spinner" spin class="mr-2" v-if="showUploadSpinner"/> -->
              {{ uploadingMessage }}
            </template>
          </div>
        </div>
      </div>
      <template v-if="open">
        <div
          class="relative flex flex-col h-full min-h-0 overflow-scroll bg-white dark:bg-neutral-300 flex-0"
        >
          <div
            v-if="!isUploading"
            class="p-6"
          >
            <button
              type="button"
              class="relative block w-full p-12 text-center border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <fa-icon
                icon="cloud-upload"
                class="mx-auto text-gray-400"
                size="3x"
              />
              <span
                class="block mt-2 text-sm font-medium text-gray-900"
              >Drag and drop content to upload</span>
            </button>
          </div>

          <TrayList
            :items="activeUploads"
            @abortUpload="handleAbortedUpload"
            @uploadError="handleUploadError"
            @uploadComplete="handleUploadComplete"
            @clear="handleClear"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { diffList } from '@utils/misc.js'
const TrayList = () => import('./assets-explorer-tray/assets-explorer-tray-list.vue')

export default {
  name: 'AssetExplorerTray',

  components: {
    TrayList
  },

  data() {
    return {
      open: false,
      activeUploads: [],
      hasUploadError: false
    }
  },

  computed: {
    hasActiveUploads() {
      return this.activeUploads.length > 0
    },

    isUploading() {
      return this.activeUploads.length > 0
    },

    progressBarClass() {
      return this.hasUploadError ? 'warning' : ''
    },

    projectUploads() {
      return this.$store.getters['assets/uploading']
    },

    uploadingMessage() {
      if (this.hasUploadError) return 'Error uploading'

      if (this.isActivelyUploading) {
        // const noun = this.activeUploading.length === 1 ? 'file' : 'files'
        return 'Uploading' // ${this.activeUploading.length} ${noun}`
      }

      // const noun = this.activeUploads.length === 1 ? 'file' : 'files'
      return 'Uploaded' // `Uploaded ${this.activeUploads.length} ${noun}`
    },

    activeUploading() {
      if (!this.isUploading) {
        return 0
      }

      let active = this.activeUploads.filter((upload) => upload.progress < 1)

      return active
    },

    showUploadSpinner() {
      if (this.hasUploadError) return false
      return this.isActivelyUploading
    },

    isActivelyUploading() {
      return this.activeUploading.length > 0
    },

    percentUploaded() {
      if (!this.isUploading) {
        return 0
      }

      let uploadTotal = this.activeUploads.reduce((runningTotal, upload) => {
        return runningTotal + upload.progress * 100
      }, 0)

      return Math.round(uploadTotal / this.activeUploads.length)
    }
  },

  watch: {
    projectUploads: function (assets) {
      this.refreshAssets(assets)
    }
  },

  mounted() {
    this.refreshAssets(this.projectUploads)
  },

  methods: {

    togglePanel() {
      this.open = !this.open
    },

    clearAll() {
      this.activeUploads.forEach((upload) => {
        this.removeAsset(upload)
      })
    },

    addAsset: function (asset) {
      this.activeUploads.push(asset)
    },

    handleClear: function (asset) {
      this.removeAsset(asset)
    },

    handleAbortedUpload: function (asset) {
      this.hasUploadError = true
    },

    handleUploadError: function (asset) {
      this.hasUploadError = true
    },

    handleUploadComplete: function (asset) {
      this.handleClear(asset)
    },

    refreshAssets: function (assets) {
      diffList({
        orig: this.activeUploads,
        update: assets,
        key: 'id',
        onNew: this.addAsset,
        onDelete: this.markAssetUploaded
      })
    },

    removeAsset: function (asset) {
      const index = this.activeUploads.findIndex(function (item) {
        return item.id === asset.id
      })
      this.activeUploads.splice(index, 1)
    },

    markAssetUploaded: function (asset) {
      // const index = this.activeUploads.findIndex(function (item) {
      //   return item.id === asset.id
      // })
      // this.activeUploads[index].status = 'complete'
      // console.log(this.activeUploads[index])
    }
  }
}
</script>
